import React, { useRef, useState, useEffect } from "react";
import "./Header.scss";
import { ImagePass } from "../../Image/ImagePass";
import { Breadcrumbs } from "../../Breadcrumbs";
import { RenderShortcode } from "../../RenderShortcode";
import { scrollTo } from "../../../utils/helpers";

export const Header = ({
  title,
  pre_text,
  sub_text,
  use_breadcrumbs,
  location,
  image,
  image_position,
  theme,
  show_arrow,
}) => {
  const headerEl = useRef(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) return null;

  const arrowClickHandler = () => {
    scrollTo(headerEl.current.offsetHeight, 350);
  };

  return (
    <div className={`main-banner theme-${theme}`} ref={headerEl}>
      <div className={`main-banner-background`} />
      {pre_text ? <div className="pre-text container">{pre_text}</div> : null}
      {use_breadcrumbs && (
        <div className="pre-text container">
          <Breadcrumbs location={location} />
        </div>
      )}
      {title ? (
        <div className="title container">
          <h1>
            <RenderShortcode content={title} />
          </h1>
        </div>
      ) : null}
      {sub_text ? (
        <div className="sub-text container">
          <RenderShortcode content={sub_text} />
        </div>
      ) : null}
      {image ? (
        <>
          <div className={`container image-${image_position}`}>
            <ImagePass src={image} />
          </div>
          {show_arrow && (
            <div
              className="arrow container"
              onClick={() => arrowClickHandler()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 39">
                <path
                  className="dynamic-fill"
                  d="M31.56,23.87a1.65,1.65,0,0,0-.06-2.3,1.58,1.58,0,0,0-2.26.06L17.6,34.09V1.62a1.6,1.6,0,1,0-3.2,0V34L2.76,21.63A1.58,1.58,0,0,0,.5,21.57a1.64,1.64,0,0,0-.06,2.3l13.3,14.18a3.16,3.16,0,0,0,4.55,0Z"
                  fillRule="evenodd"
                />
              </svg>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};
