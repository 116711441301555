import React from "react";
import { StaticQuery, graphql } from "gatsby";
import GatsbyLink from "../../GatsbyLink";
import { RenderShortcode } from "../../RenderShortcode";
import "./GlobalCta.scss";

/**
 * Creates a CTA component that can be used above the footer of a page.
 * @param {String} props.content - Custom content for the CTA
 * @param {Object} props.link - Link object
 * @param {String} props.link.url - URL for our custom link
 * @param {String} props.link.title - Title for our custom link
 * @param {Boolean} props.custom - If custom is toggled, use our link and content from props.link
 * @param {Object} props.data - our ACF data we expect
 * @param {Object} props.data.siteSettings - siteSettings object from query
 * @param {Object} props.data.siteSettings.options - options object from siteSettings
 * @param {String} props.data.siteSettings.options.globalFooterCta - CTA content, comes back as raw HTML
 * @param {Object} props.data.siteSettings.options.globalFooterCtaLink - Link object
 * @param {String} props.data.siteSettings.options.globalFooterCtaLink.url - The url to link to
 * @param {String} props.data.siteSettings.options.globalFooterCtaLink.title - The label/title for our link
 */
export const GlobalCtaPass = (props) => {
  const { data, custom } = props;
  const { options = {} } = data.siteSettings;
  const { globalFooterCta, globalFooterCtaLink } = options || {};
  const footerCtaContent = custom ? props.content : globalFooterCta;
  const footerCtaLink = custom ? props.link : globalFooterCtaLink?.url;
  return (
    <div className="global-cta">
      <div className="wrap">
        <div className="inner">
          <div className="left">
            <RenderShortcode content={footerCtaContent} />
          </div>
          <div className="right">
            <GatsbyLink to={footerCtaLink?.url} className="action">
              {footerCtaLink.title}
            </GatsbyLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export const GlobalCta = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              globalFooterCta
              globalFooterCtaLink {
                url
                title
              }
            }
          }
        }
      `}
      render={(data) => <GlobalCtaPass {...props} data={data} />}
    />
  );
};
