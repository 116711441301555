import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import "./PostList.scss";
import RenderContent from "../../RenderContent";
import Pagination from "../../Pagination";
import PostListItem from "../PostListItem";
import { PostListNavigation } from "../PostListNavigation";
import GatsbyLink from "../../GatsbyLink";
import { ImagePass } from "../../Image/ImagePass";
import { decodeEntities } from "../../../utils/helpers";
import { Card } from "../../Card";
import { dateI18n } from "@wordpress/date";

export default class IndexPage extends React.Component {
  render() {
    const {
      posts,
      title,
      pageContext,
      categoryFilter,
      pathPrefix,
      siteMetadata,
      showAuthor,
      dateFormat,
    } = this.props;
    const [featuredItem, ...postGrid] = posts;
    return (
      <section className="post-list">
        <PostListNavigation
          pathPrefix={pathPrefix}
          categoryFilter={categoryFilter}
          postSlug="/blog/"
        />
        <div className="outer">
          <div className="wrap">
            <div className="inner">
              {featuredItem && (
                <div className="featured">
                  <GatsbyLink
                    to={featuredItem.node.path}
                    className="featured-image"
                  >
                    <ImagePass src={featuredItem.node.featured_media} />
                  </GatsbyLink>
                  <Card
                    heading={decodeEntities(featuredItem.node.title)}
                    preText={featuredItem.node.categories[0].name}
                    text={<RenderContent content={featuredItem.node.excerpt} />}
                    cta={featuredItem.node.path}
                    ctaShort
                    theme="light"
                    date={dateI18n(dateFormat, featuredItem.node.date)}
                  />
                </div>
              )}
              <div className="list">
                {postGrid &&
                  postGrid.map(
                    (post, inedx) =>
                      post.node.slug !== "gatsby" && (
                        <PostListItem
                          key={inedx}
                          data={post.node}
                          dateFormat={dateFormat}
                          siteMetadata={siteMetadata}
                          pathPrefix={pathPrefix}
                          showAuthor={showAuthor}
                        />
                      )
                  )}
              </div>
              <Pagination pageContext={pageContext} pathPrefix={pathPrefix} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    categories {
      wordpress_id
      name
      slug
      path
    }
    author {
      name
      slug
      path
      avatar_urls {
        wordpress_48
      }
    }
    title
    excerpt
    date
    slug
    path
    yoast_head
    featured_media {
      source_url
      localFile {
        childImageSharp {
          fluid(maxWidth: 670, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`;
