import React from "react";
import GatsbyLink from "../GatsbyLink";
import "./Pagination.scss";

const Pagination = ({ pageContext, pathPrefix }) => {
  if (!pageContext) {
    return null;
  }
  const {
    previousPagePath = null,
    nextPagePath = null,
    numberOfPages = null,
  } = pageContext;
  var pages = [];
  for (var i = 0; i < numberOfPages; i++) {
    pages.push(i + 1);
  }
  if (pages.length === 1) {
    return null;
  }

  return (
    <nav className="pagination" role="navigation">
      <div className="navbar navbar-menu">
        {previousPagePath && (
          <GatsbyLink to={previousPagePath} className="prev">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="24"
              viewBox="0 0 12 24"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M17.8430217,12.3308826 C17.4071324,11.8742764 16.7161049,11.8922767 16.3010112,12.3722831 C15.885372,12.8522896 15.9028267,13.6118997 16.3386478,14.0691059 L24.7070685,22.799823 C25.0879351,23.1582278 25.2783684,23.5582331 25.2783684,23.9998391 C25.2783684,24.441495 25.0762986,24.8415003 24.6721591,25.1998552 L16.3386478,33.9311723 C15.9022812,34.3883784 15.885372,35.1479886 16.3010112,35.62807 C16.5153081,35.8751983 16.8028328,36 17.0908348,36 C17.3613821,36 17.6318612,35.8901985 17.8430217,35.6687956 L27.3607229,25.6966618 C27.7730894,25.2436557 28,24.6412477 28,23.9998391 C28,23.3584305 27.7730894,22.7560224 27.3416319,22.282616 L17.8430217,12.3308826 Z"
                transform="matrix(-1 0 0 1 28 -12)"
              />
            </svg>
          </GatsbyLink>
        )}
        {pages.map((key, index) => (
          <GatsbyLink
            key={index}
            to={key !== 1 ? `${pathPrefix}page/${key}` : pathPrefix}
            activeClassName="active"
          >
            {key}
          </GatsbyLink>
        ))}
        {nextPagePath && (
          <GatsbyLink to={nextPagePath} className="next">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="24"
              viewBox="0 0 12 24"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M19.8430217,12.3308826 C19.4071324,11.8742764 18.7161049,11.8922767 18.3010112,12.3722831 C17.885372,12.8522896 17.9028267,13.6118997 18.3386478,14.0691059 L26.7070685,22.799823 C27.0879351,23.1582278 27.2783684,23.5582331 27.2783684,23.9998391 C27.2783684,24.441495 27.0762986,24.8415003 26.6721591,25.1998552 L18.3386478,33.9311723 C17.9022812,34.3883784 17.885372,35.1479886 18.3010112,35.62807 C18.5153081,35.8751983 18.8028328,36 19.0908348,36 C19.3613821,36 19.6318612,35.8901985 19.8430217,35.6687956 L29.3607229,25.6966618 C29.7730894,25.2436557 30,24.6412477 30,23.9998391 C30,23.3584305 29.7730894,22.7560224 29.3416319,22.282616 L19.8430217,12.3308826 Z"
                transform="translate(-18 -12)"
              />
            </svg>
          </GatsbyLink>
        )}
      </div>
    </nav>
  );
};

export default Pagination;
